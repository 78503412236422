import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import Api from './api';
import actions from './actions';
import pageActions from '../pages/actions';

const API = new Api();

function* getPostals() {
    yield takeLatest(actions.GET_POSTALS, function* (action) {
        try {
            const {data} = action;
            const response = yield call(API.getBranches, data);
            if (response && response.status === 200) {
                if(response.data.autoComplete){
                    yield put(actions.setAutocomplete(response.data.autoComplete));
                } else {
                    yield put(pageActions.setModule(response.data));
                }
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* PostalIndex() {
    yield all([
        fork(getPostals)
    ]);
}

export default PostalIndex;