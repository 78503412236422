const actions = {
    SET_NOTIF_UI: 'SET_NOTIF_UI',

    setNotifUi: data => {
        return {
            type: actions.SET_NOTIF_UI,
            data
        }
    },
};

export default actions;