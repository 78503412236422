import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import Api from './api';
import actions from './actions';
import errorActions from '../../redux/errors/actions';
import modalActions from '../../redux/modals/actions';
import {generateMenuTree} from "../../helpers/Utils";
import loaderActions from "../loader/actions";
import notifActions from "../notifications/actions";

const API = new Api();
function* getPages() {
    yield takeLatest(actions.GET_PAGES, function* (action) {
        try {
            const {data} = action;
            const response = yield call(API.getPages, data);
            if (response && response.status === 200) {
                yield put(actions.setPages({[data]:response.data}));
                const {module, bannerGroups} = response.data;
                if(module && module.getMethod && module.getMethod !== "postalCalculator") {
                    const response = yield call(API.getModule, {path: module.getMethod});
                    if (response && response.status === 200) {
                        yield put(actions.setModule(response.data))
                    }
                }
                yield put(actions.setBanners(bannerGroups));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });
    
}

function* getModule() {
    yield takeLatest(actions.GET_MODULE, function* (action) {
        try {
            const {data} = action;
            const response = yield call(API.getModule, data);
            if (response && response.status === 200) {
                yield put(actions.setModule(response.data));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* getMenu() {
    yield takeLatest(actions.GET_MENU, function* (action) {
        try {
            yield put(actions.setMenu());
            const {data} = action;
            const response = yield call(API.getMenu, data);
            if (response && response.status === 200) {
                yield put(actions.setMenu(response.data));
                if(response.data && response.data.length)
                {
                    const menuTree = generateMenuTree(response.data);
                    yield put(actions.setMenuTree(menuTree));
                }

            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* sendFeedback() {
    yield takeLatest(actions.SEND_FEEDBACK, function* (action) {
        try {
            yield put(loaderActions.setLoaderUi({
                visible: true
            }))
            const {data,callback} = action.data;
            const response = yield call(API.sendFeedback, data);
            if (response && response.status === 200) {
                callback();
                yield put(loaderActions.setLoaderUi({
                    visible: false
                }))
                yield put(notifActions.setNotifUi({
                    visible: true,
                    success: true,
                    page: "feedback"
                }))
            }
        } catch (e) {
            yield put(loaderActions.setLoaderUi({
                visible: false
            }))
            yield put(notifActions.setNotifUi({
                visible: true,
                success: false,
                error: true,
                page: "feedback"
            }))
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            yield put(errorActions.setError({page: 'feedback', data:message}));
            console.log(message);
        }
    });

}

function* sendPartner() {
    yield takeLatest(actions.SEND_PARTNER, function* (action) {
        try {
            yield put(loaderActions.setLoaderUi({
                visible: true
            }))
            const {data,callback} = action.data;
            const response = yield call(API.sendPartner, data);
            if (response && response.status === 200) {
                callback();
                yield put(loaderActions.setLoaderUi({
                    visible: false
                }))
                yield put(notifActions.setNotifUi({
                    visible: true,
                    success: true,
                    page: "partner"
                }))
            }
        } catch (e) {
            yield put(loaderActions.setLoaderUi({
                visible: false
            }))
            yield put(notifActions.setNotifUi({
                visible: true,
                success: false,
                error: true,
                page: "partner"
            }))
            const message = {
                name: "name",
                type:"required",
                message: "danger"
            };
            yield put(errorActions.setError({page: 'become-partner', data:message}));
            console.log(e.message);
        }
    });

}

function* subscribe() {
    yield takeLatest(actions.SUBSCRIBE, function* (action) {
        try {
            const {data,callback} = action.data;
            const response = yield call(API.subscribe, data);
            if (response && response.status === 200) {
                callback();
            }
        } catch (e) {
            const message = {
                name: "name",
                type:"required",
                message: "danger"
            };
            yield put(errorActions.setError({page: 'subscribe', data:message}));
            console.log(e.message);
        }
    });

}

function* getTeam() {
    yield takeLatest(actions.GET_TEAM, function* (action) {
        try {
            const {data} = action;
            const response = yield call(API.getTeam, data);
            if (response && response.status === 200) {
                yield put(actions.setTeam(response.data));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* getStamps() {
    yield takeLatest(actions.GET_STAMPS, function* (action) {
        try {
            const {data} = action;
            const response = yield call(API.getStamps, data);
            if (response && response.status === 200) {
                yield put(actions.setStamps(response.data));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* getBlocks() {
    yield takeLatest(actions.GET_BLOCKS, function* (action) {
        try {
            const {data} = action;
            const response = yield call(API.getBlocks, data);
            if (response && response.status === 200) {
                const groupData = {
                    block_1: [],
                    block_2: [],
                    block_3: [],
                    block_4: [],
                };
                if(response.data && response.data.length) {
                    for (let i = 0; i < response.data.length; i++) {
                        groupData[`block_${response.data[i].block_type}`].push(response.data[i]);
                    }
                }
                yield put(actions.setBlocks(groupData));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* getSocials() {
    yield takeLatest(actions.GET_SOCIALS, function* (action) {
        try {
            const {data} = action;
            const response = yield call(API.getSocials, data);
            if (response && response.status === 200) {
                yield put(actions.setSocial(response.data));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}
function* getModalData() {
    yield takeLatest(actions.GET_MODAL_DATA, function* (action) {
        try {
            const {data} = action;
            const response = yield call(API.getPages, data);
            if (response && response.status === 200) {
                const {data} = response;
                yield put(modalActions.setModalUi({data, visible: true}));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* sendInsuranceForm() {
    yield takeLatest(actions.SEND_INSURANCE_FORM, function* (action) {
        try {
            yield put(loaderActions.setLoaderUi({
                visible: true
            }))
            const {data, callback, postMethod, prevPath, history} = action.data;
            const response = yield call(API.sendInsuranceForm, {data,postMethod});
            if (response && response.status === 200) {
                callback()
                yield put(modalActions.setModalUi({
                    visible: false,
                }));
                history.push(prevPath)
                yield put(loaderActions.setLoaderUi({
                    visible: false
                }))
            }
        } catch (e) {
            yield put(loaderActions.setLoaderUi({
                visible: false
            }))
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* getTariffs() {
    yield takeLatest(actions.GET_TARIFFS, function* (action) {
        try {
            const {data} = action;
            const response = yield call(API.getTariffs, data);
            if (response && response.status === 200) {
                const {data} = response;
                yield put(actions.setTariffs(data));
                yield put(actions.setAllZones(data?.zones))
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}



function* Pages() {
    yield all([
        fork(getPages),
        fork(getMenu),
        fork(sendFeedback),
        fork(sendPartner),
        fork(getTeam),
        fork(getStamps),
        fork(getBlocks),
        fork(subscribe),
        fork(getSocials),
        fork(getModalData),
        fork(sendInsuranceForm),
        fork(getTariffs),
        fork(getModule)
    ]);
}

export default Pages;