import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import Api from './api';
import actions from './actions';
import {formatTranslations} from "../../helpers/Utils";

const API = new Api();

function* getTrans() {
    yield takeLatest(actions.GET_TRANSLATIONS, function* (action) {
        try {
            const {data} = action;
            const response = yield call(API.getTrans, data);
            if (response && response.status === 200) {
                const lang = JSON.parse(localStorage.getItem('locale')).code;
                let list = response.data;
                if(response.data && response.data.length)
                    list = formatTranslations(response.data);
                yield put(actions.setTranslations({[lang]:list}));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* getSiteConfigs() {
    yield takeLatest(actions.GET_SITE_CONFIGS, function* (action) {
        try {
            const {data} = action;
            const response = yield call(API.getSiteConfigs, data);
            if (response && response.status === 200) {
                yield put(actions.setSiteConfigs(response.data));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* Settings() {
    yield all([
        fork(getTrans),
        fork(getSiteConfigs)
    ]);
}

export default Settings;