const actions = {
    SET_MODAL_UI: 'SET_MODAL_UI',

    setModalUi: data => {
        return {
            type: actions.SET_MODAL_UI,
            data
        }
    },
};

export default actions;