import actions from './actions';
import { Map } from "immutable";

const init_state = new Map({
    loader: {
        visible: false,
    }
});

const Loader = (state = init_state, action) => {
    switch (action.type) {
        case actions.SET_LOADER_UI: {
            const { data } = action;
            const merge = Object.assign({},state.get('loader'),data);
            return state.set('loader',merge);
        }
        default:
            return state;
    }
};

export default Loader;