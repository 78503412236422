import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import {Provider} from 'react-redux'
import store from "./redux/store";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import SimpleReactLightbox from 'simple-react-lightbox'
//import reportWebVitals from './reportWebVitals';

const reCaptchaKey = process.env.REACT_APP_RE_CA_CODE;

ReactDOM.render(
        <Provider store={store}>
            <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
                <SimpleReactLightbox>
                    <App/>
                </SimpleReactLightbox>
            </GoogleReCaptchaProvider>
        </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
