import {useMediaQuery} from 'react-responsive';

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 960 });
    return isDesktop ? children : null
};

const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 959 });
    return isMobile ? children : null
};

const UnderTab = ({ children }) => {
    const isUnderTab = useMediaQuery({ maxWidth: 767 });
    return isUnderTab ? children : null
};

const TabDesk = ({ children }) => {
    const isTabDesk = useMediaQuery({ minWidth: 768 });
    return isTabDesk ? children : null
};

const OnlyMobile = ({ children }) => {
    const isOnlyMobile = useMediaQuery({ maxWidth: 479 });
    return isOnlyMobile ? children : null
};

const NotMobile = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 480 });
    return isNotMobile ? children : null
};

export {
    Desktop,
    Mobile,
    UnderTab,
    TabDesk,
    OnlyMobile,
    NotMobile
}