import {Map} from "immutable";
import actions from './actions';

const init_state = new Map();


const Parcels = (state = init_state, action) => {
    switch (action.type) {
        case actions.SET_PARCEL_TYPE: {
            return state.set('parcel',action.data);
        }
        case actions.SET_PARCEL_INFO: {
            return state.set('parcelData',action.data);
        }
        case actions.SET_PARCEL_BANNERS: {
            return state.set('parcelBanners',action.data);
        }
        default:
            return state;
    }
};

export default Parcels;