import {apiRequest} from "../../service/service";

class Api {
    getJobInfo = (id=null) => {
        const url = `career/${id}`;
        const config = {
            method:'get',
            url:url
        };
        return apiRequest(config);
    };
    sendJobApply = (params = {}) => {
        const url = `careerRequest`;
        const config = {
            method:'post',
            url:url,
            data: params.data
        };
        return apiRequest(config);
    };
    getJobList = (params = {}) => {
        const url = `career`;
        const config = {
            method:'get',
            url:url,
            params
        };
        return apiRequest(config);
    };
}

export default Api;
