const actions = {
    GET_PARCEL_TYPE: 'GET_PARCEL_TYPE',
    GET_SHIPPING_METHODS: 'GET_SHIPPING_METHODS',
    SET_PARCEL_TYPE: 'SET_PARCEL_TYPE',
    GET_PARCEL_INFO: 'GET_PARCEL_INFO',
    GET_PARCEL_BANNERS: 'GET_PARCEL_BANNERS',
    SET_PARCEL_INFO: 'SET_PARCEL_INFO',
    SET_PARCEL_BANNERS: 'SET_PARCEL_BANNERS',

    getParcelType: data => {
        return {
            type: actions.GET_PARCEL_TYPE,
            data: data
        }
    },
    getShippingMethods: data => {
        return {
            type: actions.GET_SHIPPING_METHODS,
            data: data
        }
    },
    setParcelType: data => {
        return {
            type: actions.SET_PARCEL_TYPE,
            data: data
        }
    },
    getParcelInfo: data => {
        return {
            type: actions.GET_PARCEL_INFO,
            data: data
        }
    },
    getParcelBanners: data => {
        return {
            type: actions.GET_PARCEL_BANNERS,
            data: data
        }
    },
    setParcelInfo: data => {
        return {
            type: actions.SET_PARCEL_INFO,
            data: data
        }
    },
    setParcelBanners: data => {
        return {
            type: actions.SET_PARCEL_BANNERS,
            data: data
        }
    },
};

export default actions;