import React, { useEffect,useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import Date from "../../../controls/date";
import { useLocation } from "react-router-dom";

const BottomFooter = () => {
 
    const { pathname, hash } =  useLocation();
    const configs = useSelector(({ Settings }) => Settings.get("site_configs"));
    const lastUpdate = useSelector(({ Pages }) => Pages.get("lastUpdate"));
    const updatData = useSelector(({ News }) => News.get("newsInner"));
    const careerData = useSelector(({ Career }) => Career.get("jobInfo"));

    const  [mainDate, setMainDate] = useState(lastUpdate)
    
    useEffect(() => {
        if (pathname.includes("news/")) {
            setMainDate(updatData?.main.update_date)
            } else if (pathname.includes("career")) {
                if (hash) {
                    setMainDate(careerData?.update_date)
                } else {
                    setMainDate(lastUpdate)
                }
        } else {
            setMainDate(lastUpdate)
        }

    }, [pathname, updatData, careerData, hash, lastUpdate, mainDate]);
    return (
        <div className="footer_bottom">
        <div className="finance_partners">
            <a href="https://www.cba.am/ " rel="noreferrer" target="_blank">
             <img src="/resources/images/cb_logo.jpg" alt=" " title=" " />
            </a>
            <a href="https://fsm.am/" rel="noreferrer" target="_blank">
            <img src="/resources/images/fsm_logo.jpg" alt=" " title=" " />
            </a>
            <a href="https://www.abcfinance.am/" rel="noreferrer" target="_blank">
            <img src="/resources/images/abc_logo.svg" alt=" " title=" " />
            </a>
        </div>
        <div className="copyrights">{configs.copyright_info}</div>
        <div className="last_update">
            <span>Copyright © "Haypost" CJSC </span>
            <span>
            <FormattedMessage id="L_LAST_UPDATED" /> <Date date={mainDate} />
            </span>
        </div>
        </div>
    );
};

export default BottomFooter;