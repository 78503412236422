import {combineReducers} from 'redux';
import Settings from "./settings/reducer";
import OurNetwork from "./our-network/reducer";
import Pages from "./pages/reducer";
import Main from "./main/reducer";
import Parcels from "./parcels/reducer";
import News from "./news/reducer";
import Errors from "./errors/reducer";
import Modals from "./modals/reducer";
import Career from "./career/reducer";
import PostalIndex from "./postal-index/reducer";
import Calculators from "./calculators/reducer";
import Notifications from "./notifications/reducer";
import Loader from "./loader/reducer";

const combinedReducers = combineReducers({
    Settings,OurNetwork,Pages,Main,Parcels,News,Errors,Modals,Career,PostalIndex,Calculators,Notifications,Loader
});

export default combinedReducers;