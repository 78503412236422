import React, {lazy, Suspense} from "react";
import {Route} from "react-router-dom";
import DynamicRouter from "../helpers/DynamicRouter";


const Home = lazy(() => {
    return import('../pages/home')
});

const PostalServices = lazy(() => {
    return import('../pages/postal-services')
});

const Payments = lazy(() => {
    return import('../pages/payments')
});

const CorporateServices = lazy(() => {
    return import('../pages/corporate-services')
});

const Career = lazy(() => {
    return import('../pages/career')
});

const SearchResult = lazy(() => {
    return import('../pages/search-result')
});

const News = lazy(() => {
    return import('../pages/news')
});

const NewsInner = lazy(() => {
    return import('../pages/news-inner')
});

const Parcels = lazy(() => {
    return import('../pages/parcels')
});

const TrackTrace = lazy(() => {
    return import('../pages/track-trace')
});

const ErrorPage = lazy(() => {
    return import('../pages/error-page')
});

const PostalIndex = lazy(() => {
    return import('../pages/postal-index')
});


const PageRoutes = () => {
    return (
        <Suspense fallback={null}>
                <DynamicRouter>
                    <Route exact path="/">
                        <Home/>
                    </Route>
                    <Route exact path="/news/">
                        <News/>
                    </Route>
                    <Route exact path="/news/:slug">
                        <NewsInner/>
                    </Route>
                    <Route exact path="/postal-services">
                        <PostalServices/>
                    </Route>
                    <Route exact path="/payments">
                        <Payments/>
                    </Route>
                    {/*<Route exact path="/Career">*/}
                    {/*    <Career/>*/}
                    {/*</Route>*/}
                    <Route exact path="/career/:id">
                        <Career/>
                    </Route>
                    <Route exact path="/parcels">
                        <Parcels/>
                    </Route>
                    <Route exact path="/corporate-services">
                        <CorporateServices/>
                    </Route>
                    <Route exact path="/search-result">
                        <SearchResult/>
                    </Route>
                    <Route exact path="/track-trace">
                        <TrackTrace/>
                    </Route>
                    <Route exact path="/find-index/">
                        <PostalIndex/>
                    </Route>
                    <Route path="*">
                        <ErrorPage/>
                    </Route>
                </DynamicRouter>
        </Suspense>
    )
}

export default PageRoutes;