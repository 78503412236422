import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {Route, Switch, useHistory} from "react-router-dom";
import PageController from "../pages/default-page/page-controller";
import ScrollToTop from "./ScrollToTop";
import ReactGA from "react-ga";


const DynamicRouter = ({children}) => {
    const sitemap = useSelector(({Pages}) => Pages.get('menu'));
    const history = useHistory();

    useEffect(() => {
        history.listen(location => {
            ReactGA.set({page: location.pathname}); // Update the user's current page
            ReactGA.pageview(location.pathname); // Record a pageview for the given page
        });
    }, [history]);


    if(!sitemap)
        return false;

    return (
        <ScrollToTop>
            <Switch>
                {
                    sitemap.map((item,idx) => {
                        if(!item.page || (item.page && !item.page.path))
                            return false;

                        const {page} = item;

                        return (
                            <Route key={`route-${idx}`} exact path={page.path}>
                                <PageController page={page}/>
                            </Route>
                        )

                    })
                }
                {children}
            </Switch>
        </ScrollToTop>
    )


}

export default React.memo(DynamicRouter, ()=>true);

