const actions = {
    GET_POSTALS: 'GET_POSTALS',
    SET_POSTALS: 'SET_POSTALS',
    SET_PINNED_POSTAL: 'SET_PINNED_POSTAL',
    SET_REGION_CITY: 'SET_REGION_CITY',
    SET_AUTOCOMPLETE: 'SET_AUTOCOMPLETE',
    getPostals: data => {
        return {
            type: actions.GET_POSTALS,
            data: data
        }
    },
    setPostals: data => {
        return {
            type: actions.SET_POSTALS,
            data: data
        }
    },
    setPinnedPostal: data => {
        return {
            type: actions.SET_PINNED_POSTAL,
            data: data
        }
    },
    setRegionCity: data => {
        return {
            type: actions.SET_REGION_CITY,
            data: data
        }
    },
    setAutocomplete: data => {
        return {
            type: actions.SET_AUTOCOMPLETE,
            data: data
        }
    },
};

export default actions;