const actions = {
    GET_NEWS: 'GET_NEWS',
    SET_NEWS: 'SET_NEWS',
    GET_INNER: 'GET_INNER',
    SET_INNER: 'SET_INNER',
    getNews: data => {
        return {
            type: actions.GET_NEWS,
            data: data
        }
    },
    setNews: data => {
        return {
            type: actions.SET_NEWS,
            data: data
        }
    },
    getInner: data => {
        return {
            type: actions.GET_INNER,
            data: data
        }
    },
    setInner: data => {
        return {
            type: actions.SET_INNER,
            data: data
        }
    }
};

export default actions;