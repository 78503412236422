import React, { useState, useEffect, useRef } from 'react';
import { NotMobile, OnlyMobile } from '../../../../../../redux/settings/devices';
import {useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import {useIntl} from "react-intl";


const Search = ({searchDataReload}) => {
    const defaultValues = {
        term: ""
    }

    const intl = useIntl();
    const history = useHistory();
    const [visible, setVisible] = useState(false);
    const [inputValue, setValue] = useState('');
    const { register, handleSubmit } = useForm({ defaultValues });
    const refContainer = useRef();


    useEffect(() => {
        if (visible) {
            document.addEventListener('click', handleOutsideClick, false);
        } else {
            document.removeEventListener('click', handleOutsideClick, false);
        }
        return () => {
            document.removeEventListener('click', handleOutsideClick, false);
        }
    });

    const handleOutsideClick = (e) => {
        if (refContainer) {
            if(refContainer.current && refContainer.current.contains(e.target)){
                return;
            }
        }
        if(!inputValue) {
            setValue('');
            setVisible(!visible)
        }
    }

    const handleSearchClick = (data) => {
        const {term} = data;
        if(term !== ""){
            history.push(`/search-result?term=${term}`)
            setValue('');
            setVisible(false)
        }
    }

    const deleteInputValue = () => {
        setValue('');
    }
    const toggleDropDown = () => {
        setVisible(!visible)
    }

    return (
        <>
            <form className="search_form" onSubmit={handleSubmit(handleSearchClick)}>
            <NotMobile>
                <div className={`search_block ${visible ? 'open' : ''}`} ref = {refContainer}>
                    {!visible && <button type="button" className="submit_btn icon_search" onClick={toggleDropDown}></button>}
                    {visible && <button type="submit" className="submit_btn icon_search"></button>}
                    <input
                        type="text"
                        ref={register()}
                        value={inputValue}
                        name="term"
                        id="term"
                        placeholder={intl.formatMessage({id:"L_SEARCH"})}
                        onChange = {(e) => {setValue(e.target.value)}}
                    />
                    {inputValue ? <button type="button" className="clear_btn icon_close" onClick={deleteInputValue}></button> : null}
                </div>
            </NotMobile>
            <OnlyMobile>
                <div className="search_block">
                    <button type="button" className="submit_btn icon_search" onClick={toggleDropDown}></button>
                </div>
                <div className={`search_inner ${visible ? 'open' : ''}`} ref = {refContainer}>
                    {!visible && <button type="button" className="submit_btn icon_search" onClick={toggleDropDown}></button>}
                    {visible && <button type="submit" className="submit_btn icon_search"></button>}
                    <input
                        type="text"
                        ref={register()}
                        value={inputValue}
                        name="term"
                        id="term"
                        placeholder={intl.formatMessage({id:"L_SEARCH"})}
                        onChange = {(e) => {setValue(e.target.value)}}
                    />
                    {inputValue ? <button type="button" className="clear_btn icon_close" onClick={deleteInputValue}></button> : null}
                </div>
            </OnlyMobile>
            </form>
        </>
    )
}

export default Search;
