import {IntlProvider} from "react-intl";
import localTranslations from "../translations";
import {useSelector} from "react-redux";

const TranslateProvider = ({lang,children}) => {
    const translations = useSelector(({Settings})=>Settings.getIn(['translations',lang]));

    return (
        <IntlProvider locale={lang} messages={translations ? translations : localTranslations[lang]}>
            {children}
        </IntlProvider>
    )
}

export default TranslateProvider;