import { createStore,compose,applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import combinedReducers from "./reducers"
import combinedSagas from './sagas'


const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = process.env.NODE_ENV !== 'production'
&& typeof window === 'object'
&& window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 20 })
    : compose;


const store = createStore(combinedReducers,composeEnhancers(applyMiddleware(sagaMiddleware)));


sagaMiddleware.run(combinedSagas);

export default store;