const actions = {
    GET_BRANCHES: 'GET_BRANCHES',
    SET_BRANCHES: 'SET_BRANCHES',
    SET_PINNED: 'SET_PINNED',
    SET_NETWORK_AUTOCOMPLETE: 'SET_NETWORK_AUTOCOMPLETE',
    getBranches: data => {
        return {
            type: actions.GET_BRANCHES,
            data: data
        }
    },
    setBranches: data => {
        return {
            type: actions.SET_BRANCHES,
            data: data
        }
    },
    setPinned: data => {
        return {
            type: actions.SET_PINNED,
            data: data
        }
    },
    setNetworkAutocomplete: data => {
        return {
            type: actions.SET_NETWORK_AUTOCOMPLETE,
            data: data
        }
    },
};

export default actions;