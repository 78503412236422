import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import Api from './api';
import actions from './actions';

const API = new Api();

function* doSearch() {
    yield takeLatest(actions.DO_SEARCH, function* (action) {
        try {
            const {data} = action;
            const response = yield call(API.doSearch, data);
            if (response && response.status === 200) {
                yield put(actions.setSearchResults(response.data));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* Pages() {
    yield all([
        fork(doSearch)
    ]);
}

export default Pages;