import {apiRequest} from "../../service/service";

class Api {
    getNews = (params = {}) => {
        const url = `news`;
        const config = {
            method:'get',
            url:url,
            params
        };
        return apiRequest(config);
    };
    getInner = (id) => {
        const url = `news/${id}`;
        const config = {
            method:'get',
            url:url
        };
        return apiRequest(config);
    };
}

export default Api;
