import { apiRequest } from "../../service/service";

class Api {
    getCurrencies = (params) => {
        const url = `countryCurrencies`;
        const config = {
            method: 'get',
            url: url,
            params
        };
        return apiRequest(config);
    };
    getTransfers = (params) => {
        const url = `moneyTransfer`;
        const config = {
            method: 'get',
            url: url,
            params
        };
        return apiRequest(config);
    };
    getSections = (page) => {
        const url = `page/${page}`;
        const config = {
            method: 'get',
            url: url
        };
        return apiRequest(config);
    };
    getModule = (path = {}) => {
        const url = `${path}`;
        const config = {
            method: 'get',
            url: url
        };
        return apiRequest(config);
    };

    getPostalCalculator = (params) => {
        const url = `postalCalculator`;
        const config = {
            method: 'get',
            url: url,
            params
        };
        return apiRequest(config);
    };

    getAdditionalTariffs = (params) => {
        const url = `postalAdditionalTariffs`;
        const config = {
            method: 'get',
            url: url,
            params
        };
        return apiRequest(config);
    };

    sendDelayForm = (params = {}) => {
        const url = `/delayReport`;
        const config = {
            method: 'post',
            url: url,
            data: params.data
        };
        return apiRequest(config);
    };
}

export default Api;
