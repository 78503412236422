import {Route, Redirect} from "react-router-dom";
import {AppLanguages} from "./Constants";
import { BrowserRouter } from 'react-router-dom';
import TranslateProvider from "./TranslateProvider";
import {useDispatch} from "react-redux";
import actions from "../redux/settings/actions";

const LocalizedRouter = ({selectedLang, defaultLang, children}) => {
    const dispatch = useDispatch();
    return (
        <BrowserRouter>
            <Route path="/:lang/">
                {({ match, location }) => {
                    const oldLang = JSON.parse(localStorage.getItem('locale'));
                    /**
                     * Get current language
                     * Set default locale to en if base path is used without a language
                     */
                    const params = match ? match.params : {};


                    /**
                     * ToDo
                     * make default metas translatable
                     */


                    const lang = AppLanguages[params.lang] ? AppLanguages[params.lang] : selectedLang;
                    const { code } = lang;
                    //const {code} = selectedLang;

                    /**
                     * If language is not in route path, redirect to language root
                     */

                    const { search } = location;
                    const { pathname } = window.location;


                    if(oldLang && oldLang.id !== selectedLang.id){
                        localStorage.setItem('locale',JSON.stringify(selectedLang));
                        const path = pathname.replace(`/${code}`,'');
                        return <Redirect to={`/${selectedLang.code}${path}${search}`} />;
                    }

                    else if (!pathname.includes(`/${code}`)) {
                        return <Redirect to={`/${code}${pathname}${search}`} />;
                    }

                    if(oldLang && oldLang.id !== lang.id){
                        dispatch(actions.setLanguage(lang));
                    }



                    localStorage.setItem('locale',JSON.stringify(selectedLang))
                    /**
                     * Return Intl provider with default language set
                     */
                    return (
                        <TranslateProvider lang={code}>
                            <BrowserRouter basename={code}>
                                {children}
                            </BrowserRouter>
                        </TranslateProvider>
                    );
                }}
            </Route>
        </BrowserRouter>
    )
}

export default LocalizedRouter;