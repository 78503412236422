import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import Api from './api';
import actions from './actions';
import {FilterNews} from "../../helpers/Utils";

const API = new Api();

function* getNews() {
    yield takeLatest(actions.GET_NEWS, function* (action) {
        try {
            const {data} = action;
            const response = yield call(API.getNews, data);
            if (response && response.status === 200) {
                let {data} = response;
                if(!data.count){
                    yield put(actions.setNews({data: data}));
                } else {
                    if(data.page === 1)
                        data = FilterNews(data);

                    yield put(actions.setNews(data));
                }
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* getInner() {
    yield takeLatest(actions.GET_INNER, function* (action) {
        try {
            const {data} = action;
            const response = yield call(API.getInner, data);
            if (response && response.status === 200) {
                yield put(actions.setInner(response.data));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* News() {
    yield all([
        fork(getNews),
        fork(getInner)
    ]);
}

export default News;