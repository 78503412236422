import {Map} from "immutable";
import actions from './actions';

const init_state = new Map();


const OurNetwork = (state = init_state, action) => {
    switch (action.type) {
        case actions.SET_BRANCHES: {
            return state.set('branches',action.data);
        }
        case actions.SET_PINNED: {
            return state.set('pinned',action.data);
        }
        case actions.SET_NETWORK_AUTOCOMPLETE: {
            return state.set('autocomplete',action.data);
        }

        default:
            return state;
    }
};

export default OurNetwork;