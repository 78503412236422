import actions from './actions';
import { Map } from "immutable";

const init_state = new Map({
    modal: {
        visible: false,
    }
});

const Modals = (state = init_state, action) => {
    switch (action.type) {
        case actions.SET_MODAL_UI: {
            const { data } = action;
            const merge = Object.assign({},state.get('modal'),data);
            return state.set('modal',merge);
        }
        default:
            return state;
    }
};

export default Modals;