const actions = {
    GET_JOB_INFO: 'GET_JOB_INFO',
    SET_JOB_INFO: 'SET_JOB_INFO',
    SEND_JOB_APPLY: 'SEND_JOB_APPLY',
    GET_JOB_LIST: 'GET_JOB_LIST',

    getJobInfo: data => {
        return {
            type: actions.GET_JOB_INFO,
            data: data
        }
    },
    setJobInfo: data => {
        return {
            type: actions.SET_JOB_INFO,
            data: data
        }
    },
    sendJobApply: data => {
        return {
            type: actions.SEND_JOB_APPLY,
            data: data
        }
    },
    getJobList: data => {
        return {
            type: actions.GET_JOB_LIST,
            data: data
        }
    },
};

export default actions;