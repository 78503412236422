import {apiRequest} from "../../service/service";

class Api {
    getPages = (pageId=null) => {
        const url = `page/${pageId}`;
        const config = {
            method:'get',
            url:url
        };
        return apiRequest(config);
    };
    getMenu = (params = {}) => {
        const url = `menu`;
        const config = {
            method:'get',
            url:url,
            params
        };
        return apiRequest(config);
    };
    sendFeedback = (params = {}) => {
        const url = `feedback`;
        const config = {
            method:'post',
            url:url,
            data:params
        };
        return apiRequest(config);
    };
    sendPartner = (params = {}) => {
        const url = `becomePartner`;
        const config = {
            method:'post',
            url:url,
            data: params
        };
        return apiRequest(config);
    };
    subscribe = (params = {}) => {
        const url = `postmarket`;
        const config = {
            method:'post',
            url:url,
            data: params
        };
        return apiRequest(config);
    };
    getTeam = (params = {}) => {
        const url = `teamMembers`;
        const config = {
            method:'get',
            url:url,
            params
        };
        return apiRequest(config);
    };
    getStamps = (params = {}) => {
        const url = `stamps`;
        const config = {
            method:'get',
            url:url,
            params
        };
        return apiRequest(config);
    };
    getModule = (params = {}) => {
        const url = `${params.path}`;
        const config = {
            method:'get',
            url:url,
            params: {
                local: params.local
            }
        };
        if(params.path === 'network' || params.path ===  'postalIndex') {
            config.params.limit = 1000
        }
        return apiRequest(config);
    };
    getBlocks = () => {
        const url = `block`;
        const config = {
            method:'get',
            url:url
        };
        return apiRequest(config);
    };
    getSocials = () => {
        const url = `social`;
        const config = {
            method:'get',
            url:url
        };
        return apiRequest(config);
    };
    sendInsuranceForm = (params = {}) => {
        const url = `${params.postMethod}`;
        const config = {
            method:'post',
            url:url,
            data: params.data
        };
        return apiRequest(config);
    };
    getTariffs = (params={}) => {
        const url = `/postalInternational/${params.id}`;
        const config = {
            method:'get',
            url:url,
            params: params.data
        };
        return apiRequest(config);
    };
    

}

export default Api;
