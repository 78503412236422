import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import Api from './api';
import actions from './actions';

const API = new Api();

function* getCurrencies() {
    yield takeLatest(actions.GET_CURRENCIES, function* (action) {
        try {
            const {data} = action;
            const response = yield call(API.getCurrencies, {country_id:data.country_id});
            if (response && response.status === 200) {
                yield put(actions.setCurrencies({calculator:data.calculator,currencies:response.data}));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* getMoneyTransfer() {
    yield takeLatest(actions.GET_MONEY_TRANSFER, function* (action) {
        try {
            const {data} = action;
            const response = yield call(API.getTransfers, data);
            if (response && response.status === 200) {
                const {data} = response;
                yield put(actions.setMoneyTransfer(data));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* getSections() {
    yield takeLatest(actions.GET_SECTIONS, function* (action) {
        try {
            const {data} = action;
            const response = yield call(API.getSections, data.page);
            if (response && response.status === 200) {
                const section = response.data;
                if(section && section.module && section.module.getMethod) {
                    const response = yield call(API.getModule, section.module.getMethod);
                    if (response && response.status === 200) {
                        section.moduleData = response.data;
                    }
                }
                yield put(actions.setSections({calculator:data.calculator,data:section}));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* getPostalCalculator() {
    yield takeLatest(actions.GET_POSTAL_CALCULATOR, function* (action) {
        try {
            const {data} = action;
            const response = yield call(API.getPostalCalculator, data);
            if (response && response.status === 200) {
                const {data} = response;
                yield put(actions.setPostalCalculator(data));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* getAdditionalTariffs() {
    yield takeLatest(actions.GET_ADDITIONAL_TARIFFS, function* (action) {
        try {
            const {data} = action;
            const response = yield call(API.getAdditionalTariffs, data);
            if (response && response.status === 200) {
                const {data} = response;
                yield put(actions.setAdditionalTariffs(data));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* sendJobApply() {
    yield takeLatest(actions.SEND_DELAY_FORM, function* (action) {
        try {
            const {callback} = action.data;
            const response = yield call(API.sendDelayForm, action.data);
            if (response && response.status === 200) {
                callback()
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* Calculators() {
    yield all([
        fork(getCurrencies),
        fork(getMoneyTransfer),
        fork(getSections),
        fork(getPostalCalculator),
        fork(getAdditionalTariffs),
        fork(sendJobApply)
    ]);
}

export default Calculators;