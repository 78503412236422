import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import settingsActions from "./redux/settings/actions";
import pagesActions from "./redux/pages/actions";
import { isTouchDevice } from "./redux/settings/utils";
import Header from "./components/header";
import Footer from "./components/footer";
import PageRoutes from "./routes/page-routes";
import LocalizedRouter from "./helpers/LocalizedRouter";

import './App.css';


import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'
import { YMInitializer } from "react-yandex-metrika";

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_CODE
}

const GA_CODE = process.env.REACT_APP_GA_CODE;

ReactGA.initialize(GA_CODE);
ReactGA.pageview(window.location.pathname + window.location.search);

TagManager.initialize(tagManagerArgs)

window.onerror = function (msg, url, line, col, error) {
    ReactGA.exception({
        description: `message: ${msg}, url: ${url}, line: ${line}, column: ${col}`,
        fatal: true
    });
};

function App() {
    const dispatch = useDispatch();
    const selected_language = useSelector(({ Settings }) => Settings.get('selected_language'));
    const default_language = useSelector(({ Settings }) => Settings.get('default_language'));
    const getInitialSettings = useCallback(() => {
        dispatch(settingsActions.getSiteConfigs());
        dispatch(settingsActions.getTranslations());
        dispatch(pagesActions.getMenu());
        dispatch(pagesActions.getSocial());
    }, [dispatch]);
    const liveChatIntegration = useCallback((selected_language) => {
        const language = selected_language.code || default_language.code || 'hy';
        const liveChatLng = language === 'am' ? 174258 :
            language === 'en' ? 174256 :
                language === 'ru' ? 174257 :
                    174258;
        window[`ltLang`] = language === 'am' ? 'hy' : language;
        window['liveTex'] = true;
        window['liveTexID'] = liveChatLng;
        window['liveTex_object'] = true;

        var t = document['createElement']('script');
        t.type = 'text/javascript';
        t.async = true;
        t.src = '//cs15.livetex.ru/js/client.js';
        var c = document['getElementsByTagName']('script')[0];
        if (c) c['parentNode']['insertBefore'](t, c);
        else document['documentElement']['firstChild']['appendChild'](t);
    }, [default_language]);

    useEffect(() => {
        liveChatIntegration(selected_language)
        getInitialSettings();
    }, [getInitialSettings, selected_language, liveChatIntegration])

    if (isTouchDevice()) {
        document.body.classList.add('touch')
    } else {
        document.body.classList.add('web')
    }

    return (
        <LocalizedRouter selectedLang={selected_language} defaultLang={default_language}>
            <YMInitializer accounts={[79650175]} options={{ webvisor: true }} />
            <Header />
            <PageRoutes />
            <Footer />
        </LocalizedRouter>
    );
}

export default App;
