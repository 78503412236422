import {Map} from "immutable";
import actions from './actions';

const init_state = new Map({
    site_configs: [],
    page_items_count: 12,
    selected_language: JSON.parse(localStorage.getItem('locale')) ? JSON.parse(localStorage.getItem('locale')) : {id:1,code:"am",label:"Հայ"},
    default_language: {id:1,code:"am",label:"Հայ"},
    languages: [
        {id:1,code:"am",label:"Հայ"},
        {id:2,code:"ru",label:"Рус"},
        {id:3,code:"en",label:"Eng"}
    ]
});


const Settings = (state = init_state, action) => {
    switch (action.type) {
        case actions.SET_TRANSLATIONS: {
            return state.set('translations',action.data);
        }
        case actions.SET_LANGUAGE: {
            return state.set('selected_language',action.data);
        }
        case actions.SET_SITE_CONFIGS: {
            return state.set('site_configs',action.data);
        }
        default:
            return state;
    }
};

export default Settings;