import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import Api from './api';
import actions from './actions';
import pageActions from "../pages/actions";
import loaderActions from "../loader/actions"
import notifActions from "../notifications/actions";

const API = new Api();

function* getJobInfo() {
    yield takeLatest(actions.GET_JOB_INFO, function* (action) {
        try {
            const {data} = action;
            const response = yield call(API.getJobInfo, data);
            if (response && response.status === 200) {
                yield put(actions.setJobInfo(response.data));
                // if (response.data.module.getMethod) {
                //
                // }
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* sendJobApply() {
    yield takeLatest(actions.SEND_JOB_APPLY, function* (action) {
        try {
            yield put(loaderActions.setLoaderUi({
                visible: true
            }))
            const {callback} = action.data;
            const response = yield call(API.sendJobApply, action.data);
            if (response && response.status === 200) {
                callback()
                yield put(loaderActions.setLoaderUi({
                    visible: false
                }))
                yield put(notifActions.setNotifUi({
                    visible: true,
                    success: true,
                    page: "job_apply"
                }))
            }
        } catch (e) {
            yield put(loaderActions.setLoaderUi({
                visible: false
            }));
            yield put(notifActions.setNotifUi({
                visible: true,
                success: false,
                error: true,
                page: "job_apply"
            }))
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* getJobList() {
    yield takeLatest(actions.GET_JOB_LIST, function* (action) {
        try {
            const {data} = action;
            const response = yield call(API.getJobList, data);
            if (response && response.status === 200) {
                yield put(pageActions.setModule(response.data));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* Career() {
    yield all([
        fork(getJobInfo),
        fork(sendJobApply),
        fork(getJobList)
    ]);
}

export default Career;