const absolutePath = (path) => {
    const pattern = /^https?:\/\//i;
    return pattern.test(path);
};

const changeToScript = (s) => {
    const pattern = new RegExp('<[^>]*script>', 'g');
    const pureScript = s.replace(pattern, "")
    const script = document.createElement('script');
    script.text = pureScript;
    return script;
}

const getScripts = (data) => {
    const pattern = new RegExp('<script\\b[^<]*(?:(?!<\\/script>)<[^<]*)*<\\/script>', 'gi');
    const matches = data.matchAll(pattern);
    let scripts = [];
    for (const match of matches) {
        scripts.push(changeToScript(match[0]));
    }
    return scripts;
}

const removeScripts = (data) => {
    const pattern = new RegExp('<script\\b[^<]*(?:(?!<\\/script>)<[^<]*)*<\\/script>', 'gi');
    const html = data.replace(pattern, '<div class="banner-block"></div>');
    return html;
}

const wrapTable = (data) => {
    const pattern = new RegExp('<table\\b[^<]*(?:(?!<\\/table>)<[^<]*)*<\\/table>', 'gi');
    let changedHtml;
    if(data){
        changedHtml = data;
        const matches = data.matchAll(pattern);
        for (const match of matches) {
            changedHtml = changedHtml.replace(match[0],`<div class="table_container">${match[0]}</div>`);
        }
        return changedHtml
    }
}

const FilterNews = (news) => {
    let result = {};
    result.news = news.filter((elem)=>{
            if(elem.main && !result.mainNews){
                result.mainNews = elem;
                return false;
            }
            else
            {
                return true;
            }
        });
    return result;
}

const generateMenuTree = (data) => {
    const menuObj = {};
    for (const element of data) {
        const parent_id = element.parent_id ? element.parent_id : 0;
        menuObj[parent_id] = menuObj[parent_id] ? menuObj[parent_id] : [];
        menuObj[parent_id].push(element);
    }
    return menuObj;
}

const formatTranslations = (trans) => {
    const obj = {};
    for (const item of trans){
        obj[item.term] = item.translations;
    }
    return obj;
}

export{
    absolutePath,
    getScripts,
    removeScripts,
    FilterNews,
    generateMenuTree,
    formatTranslations,
    wrapTable
}