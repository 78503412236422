import React from "react";
import { Mobile} from "../../../../redux/settings/devices";
import { Link } from "react-router-dom";
import MenuTree from "../../../menu-tree/menu-tree";
import TopHeaderMenu from "../top-header/components/top-header-menu";
import {useSelector} from "react-redux";



const HeaderMenu = () => {

    const selected_language = useSelector(({Settings})=>Settings.get('selected_language'));

    const handleClick = () => {
        if(!document.body.classList.contains('menu_opened')) {
            document.body.classList.add('menu_opened');
        } else {
            document.body.classList.remove('menu_opened');
        }
    };

    return (
            <div className="header_bottom">
                <div className="logo_block">
                    {
                        selected_language.code === "am" ?
                            <Link to="/">
                                <img src="/resources/images/main_logo0.jpg_1.svg" alt=" " title=" "/>
                            </Link>
                            : <Link to="/">
                                <img src="/resources/images/hp_eng_logo.svg" alt=" " title=" "/>
                            </Link>
                    }

                </div>
                <div className="menu_block">
                    <div className="menu_inner">
                        <Mobile>
                            <TopHeaderMenu position="header" className="website"/>
                        </Mobile>
                        <MenuTree position="main" className="main"/>
                    </div>
                </div>
                <Mobile>
                    <button className="menu_btn" onClick={handleClick}>
                        <span></span>
                    </button>
                </Mobile>
            </div>
    )
};

export default HeaderMenu;