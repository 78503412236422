import {apiRequest} from "../../service/service";

class Api {
    getTrans = (params = {}) => {
        const url = `translations`;
        const config = {
            method:'get',
            url:url,
            params
        };
        return apiRequest(config);
    };
    getSiteConfigs = (params = {}) => {
        const url = `siteConfigs`;
        const config = {
            method:'get',
            url:url,
            params
        };
        return apiRequest(config);
    };
}

export default Api;
