import React, {Fragment, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import MenuItem from "./menu-item";
import {NavLink} from "react-router-dom";
import "./menu-tree.scss";

const MenuTree = ({position='left',className,start=0}) => {

    const [parent,setParent] = useState('');
    const menuTree = useSelector(({Pages})=>Pages.get('menuTree'));
    const menu = useSelector(({Pages})=>Pages.get('menu'));

    useEffect(()=>{
        const parentItem = menu && menu.find(item => item.id === start);
        setParent(parentItem);
    }, [menu,start])

    if(!menuTree)
        return false;

    return (
        <ul className={`${className}_menu`}>
            {parent && parent.page &&
                <li className="parent">
                    <NavLink
                        exact
                        to={parent.page.path}>{parent.page.title}</NavLink>
                </li>
            }
            {
                menuTree[start] && menuTree[start].map((item)=>{
                    return (
                        <Fragment key={`menu-item-${item.id}`}>
                            {item[position] && <MenuItem item={item} position={position}/>}
                        </Fragment>
                    )
                })
            }
        </ul>
    )
}

export default MenuTree;