import {Map} from "immutable";
import actions from './actions';

const init_state = new Map();


const PostalIndex = (state = init_state, action) => {
    switch (action.type) {
        case actions.SET_POSTALS: {
            return state.set('postals',action.data);
        }
        case actions.SET_PINNED_POSTAL: {
            return state.set('pinned',action.data);
        }
        case actions.SET_REGION_CITY: {
            const { data } = action;
            const merge = Object.assign({},state.get('selectedRegionCity'),data);
            return state.set('selectedRegionCity',merge);
        }
        case actions.SET_AUTOCOMPLETE: {
            return state.set('autocomplete',action.data);
        }
        default:
            return state;
    }
};

export default PostalIndex;