const actions = {
    SET_LOADER_UI: 'SET_LOADER_UI',

    setLoaderUi: data => {
        return {
            type: actions.SET_LOADER_UI,
            data
        }
    },
};

export default actions;