import React, {Fragment} from "react";
import TopHeader from "./components/top-header";
import HeaderMenu from "./components/header-menu";

import './header.scss';

const Header = () => {
    return (
        <Fragment>
            <header className="header">
                <TopHeader/>
                <HeaderMenu/>
            </header>
        </Fragment>
    )
};

export default Header;