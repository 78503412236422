const actions = {
    SET_ERROR: 'SET_ERROR',
    setError: data => {
        return {
            type: actions.SET_ERROR,
            data: data
        }
    }
};

export default actions;