import React, {useCallback, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../redux/settings/actions";

const LangSwitcher = () => {
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const languages = useSelector(({Settings})=>Settings.get('languages'));
    const selected_language = useSelector(({Settings})=>Settings.get('selected_language'));
    const refContainer = useRef();

    const blurFunction = useCallback((event) => {
        event.stopPropagation();
        setTimeout(()=>{
            setVisible(false)
        },100)

    },[])

    useEffect(() => {
        const element = refContainer.current;
        element.addEventListener('blur',blurFunction,true);
        return () => {
            element.removeEventListener('blur',blurFunction,true);
        }

    },[blurFunction]);

    const setLangClick = (index) => {
        dispatch(actions.setLanguage(languages[index]));

    }


    const toggleDropDown = () => {
        setVisible(!visible)
    }

    return (
        <div className={`lg_block ${visible ? 'open' : ''}`} ref={refContainer}>
            <button className="lg_btn icon_down_thick" onClick={toggleDropDown}>{selected_language.label}</button>
            <ul className="lg_list">
                {
                    languages.map((item,index)=>{
                        return item.id !== selected_language.id ? <li key={item.id} onClick={()=>setLangClick(index)}>{item.label}</li> : false
                    })
                }
            </ul>
        </div>
    )
}

export default LangSwitcher;