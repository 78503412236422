import {Fragment} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

const TopHeaderMenu = ({position='left',className,start=0}) => {
    const menuTree = useSelector(({Pages})=>Pages.get('menuTree'));
    if(!menuTree)
        return false;

    return (
        <ul className={`${className}_menu`}>
            {
                menuTree[start] && menuTree[start].map((item)=>{
                    return (
                        <Fragment key={`menu-item-${item.id}`}>
                            { item[position] &&
                                menuTree[item.id] && menuTree[item.id].map((item)=>{
                                    return(
                                        <Fragment key={`menu-item-${item.id}`}>
                                            { item[position] &&
                                                <li className="submenu_btn">
                                                    <Link to={item.page ? item.page.path : item.url ? item.url : "/"}>{item.title}</Link>
                                                </li>
                                            }
                                        </Fragment>
                                    )
                                })
                            }
                        </Fragment>
                    )
                })
            }
        </ul>
    )
}

export default TopHeaderMenu;