import {NavLink, useHistory} from "react-router-dom";
import React, { useRef, useEffect } from "react";
import {useSelector} from "react-redux";
import {isTouchDevice} from "../../redux/settings/utils";

const MenuItem = ({item,position}) => {
    const menuTree = useSelector(({Pages})=>Pages.get('menuTree'));
   // const [opened,setOpen] = useState(false);
   const history = useHistory();
    const {page} = item;
    let delayTime = null;
    const node = useRef();
    const openSubWithHover = (e) => {
        if(!isTouchDevice()) {
            e.preventDefault();
            const $menuItem = e.target.parentNode;
            $menuItem.classList.add('hovered');
            if (delayTime) {
                clearTimeout(delayTime);
            }
            delayTime = setTimeout(() => {
                if($menuItem.classList.contains('hovered')) {
                    $menuItem.classList.add('opened');
                }
            },300)
        }
        
    }



    const mouseLeaveItem = (e) => {
        if(!isTouchDevice()) {
            e.preventDefault();
            e.target.parentNode.classList.remove('hovered');
        }
    }

    const closeSubWithHover = (e) => {
        if(!isTouchDevice()) {
            e.preventDefault();
           e.currentTarget.classList.remove('opened');
        }
    }

    const openSubWithTouch = (e) => {
        if(window.innerWidth < 960) {
            if(e.currentTarget.parentNode.classList.contains('opened')) {
                e.currentTarget.parentNode.classList.remove('opened');
            } else {
                e.currentTarget.parentNode.classList.add('opened');
            }
        }
        if(isTouchDevice() && window.innerWidth > 960) {
            e.currentTarget.parentNode.classList.add('opened');
            
            
        }
    }


    useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", closeSubWithTouch);
        // return function to be called when unmounted
        return () => {
          document.removeEventListener("mousedown", closeSubWithTouch);
        };
      }, []);

      useEffect(()=>{
        return history.listen((location) => { 
            if(document.getElementsByClassName("opened")[0]){
                document.getElementsByClassName("opened")[0].classList.remove("opened");
            }
            if(document.body.classList.contains('menu_opened')){
                document.body.classList.remove('menu_opened');
            }
            })
      },[history])

      const closeSubWithTouch = e => {
        if (node.current && !node.current.contains(e.target) && isTouchDevice() && window.innerWidth >= 960) {
            node.current.classList.remove('opened');
        }
      };

    return (
        
            <li className={menuTree[item.id] && "has_submenu"} onMouseLeave={menuTree[item.id] && closeSubWithHover} ref={menuTree[item.id] && node}>
                {
                    item.url
                        ? <>
                            <a href={`${item.url}`} className={menuTree[item.id] && "submenu_btn"} onMouseOver={menuTree[item.id] && openSubWithHover} onMouseLeave={menuTree[item.id] && mouseLeaveItem} rel="noreferrer" >{item.title}</a>
                            {menuTree[item.id] && <span className="touch_layer" onClick={openSubWithTouch}></span>}
                        </>
                        : <>
                            {
                                page && page.path ?
                                    <NavLink
                                        exact
                                        to={page.path}
                                        onMouseOver={menuTree[item.id] && openSubWithHover}
                                        className={menuTree[item.id] && "submenu_btn"}
                                        onMouseLeave={menuTree[item.id] && mouseLeaveItem}>{item.title}</NavLink>
                                    : <span className={menuTree[item.id] && "submenu_btn"} >{item.title}</span>
                            }
                            {menuTree[item.id] && <span className="touch_layer" onClick={openSubWithTouch}></span>}
                        </>
                }

                {
                    menuTree[item.id] && (
                        <div className="submenu_list" >
                            <ul>
                                {
                                    menuTree[item.id].map((sub)=>{

                                        return sub[position] ?  <MenuItem item={sub} key={`menu-item-${sub.id}`} position={position}/> : null;
                                    })
                                }
                            </ul>
                        </div>
                    )
                }
            </li>
    
    )
}

export default MenuItem;