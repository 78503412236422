import actions from './actions';
import { Map } from "immutable";

const init_state = new Map({
    notification: {
        visible: false,
        success: false,
        error: false,
        page: ''
    }
});

const Notifications = (state = init_state, action) => {
    switch (action.type) {
        case actions.SET_NOTIF_UI: {
            const { data } = action;
            const merge = Object.assign({},state.get('notification'),data);
            return state.set('notification',merge);
        }
        default:
            return state;
    }
};

export default Notifications;