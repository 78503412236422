import { Desktop } from "../../../../redux/settings/devices";
import Search from "./components/search";
import LangSwitcher from "../../../lang-switcher";
import TopHeaderMenu from "./components/top-header-menu";
import {FormattedMessage} from "react-intl";

const TopHeader = () => {


    return (
        <div className="header_top">
            <div className="warning_info"><span className="short_part"><FormattedMessage id="top_header_short" /></span> <FormattedMessage id="top_header_long" /></div>
            <Search/>
            <Desktop>
                <TopHeaderMenu position="header" className="website"/>
            </Desktop>
            {/*<button className="sign_in_btn">Մուտք</button>*/}
            <LangSwitcher/>
        </div>
    )
};

export default TopHeader;