const actions = {
    DO_SEARCH: 'DO_SEARCH',
    SEARCH_RESULTS: 'SEARCH_RESULTS',
    doSearch: data => {
        return {
            type: actions.DO_SEARCH,
            data: data
        }
    },
    setSearchResults: data => {
        return {
            type: actions.SEARCH_RESULTS,
            data: data
        }
    }
};

export default actions;