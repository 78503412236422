import {Map} from "immutable";
import actions from './actions';

const init_state = new Map({
});


const Main = (state = init_state, action) => {
    switch (action.type) {
        case actions.SEARCH_RESULTS: {
            return state.set('searchResults',action.data);
        }
        default:
            return state;
    }
};

export default Main;