import { lazy } from "react";


const Home = lazy(() => {
    return import('../pages/home')
});

const PostalServices = lazy(() => {
    return import('../pages/postal-services')
});

const LegalLicense = lazy(() => {
    return import('../pages/legal-licences')
});

const Payments = lazy(() => {
    return import('../pages/payments')
});

const ConsumerRight = lazy(() => {
    return import('../pages/legal-cr')
})

const CorporateServices = lazy(() => {
    return import('../pages/corporate-services')
});

const CareerJoinUs = lazy(() => {
    return import('../pages/career')
});

const Career = lazy(() => {
    return import('../pages/career')
});

const Feedback = lazy(() => {
    return import('../pages/give-feedback')
});

const Procurement = lazy(() => {
    return import('../pages/procurement')
});

const Network = lazy(() => {
    return import('../pages/our-network')
});

const BecomePartner = lazy(() => {
    return import('../pages/become-partner')
});

const SearchResult = lazy(() => {
    return import('../pages/search-result')
});

const Faq = lazy(() => {
    return import('../pages/faq')
});

const Postmarket = lazy(() => {
    return import('../pages/postmarket')
});

const Team = lazy(() => {
    return import('../pages/our-team')
});

const Stamps = lazy(() => {
    return import('../pages/stamps')
});

const News = lazy(() => {
    return import('../pages/news')
});

const NewsInner = lazy(() => {
    return import('../pages/news-inner')
});

const Parcels = lazy(() => {
    return import('../pages/parcels')
});

const Insurance = lazy(() => {
    return import('../pages/insurance')
});

const InsuranceTravel = lazy(() => {
    return import('../pages/insurance')
});

const InsuranceCMTPL = lazy(() => {
    return import('../pages/insurance')
});

const BrandMaterial = lazy(() => {
    return import('../pages/brand-material')
});

const PostalTariffs = lazy(() => {
    return import('../pages/postal-tariffs')
});

const PostalIndex = lazy(() => {
    return import('../pages/postal-index')
});

const PostalCalculator = lazy(() => {
    return import('../pages/postage-calculator')
})

const MoneyTransfer = lazy(() => {
    return import('../pages/money-transfer')
});

const ProhibitedItems = lazy(() => {
    return import('../pages/prohibited-items')
});

const FinancialReports = lazy(() => {
    return import('../pages/financial-reports')
});


export {
    Home,
    PostalServices,
    LegalLicense,
    Payments,
    ConsumerRight,
    CorporateServices,
    CareerJoinUs,
    Career,
    Feedback,
    Procurement,
    Network,
    BecomePartner,
    SearchResult,
    Faq,
    Postmarket,
    Team,
    Stamps,
    News,
    NewsInner,
    Parcels,
    Insurance,
    InsuranceTravel,
    InsuranceCMTPL,
    BrandMaterial,
    PostalTariffs,
    PostalCalculator,
    PostalIndex,
    MoneyTransfer,
    ProhibitedItems,
    FinancialReports
}



/*
BrandMaterial
*/
