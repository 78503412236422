import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import Api from './api';
import actions from './actions';

const API = new Api();

function* getParcelType() {
    yield takeLatest(actions.GET_PARCEL_TYPE, function* (action) {
        try {
            const {data} = action;
            const response = yield call(API.getParcelType, data);
            if (response && response.status === 200) {
                yield put(actions.setParcelType(response.data));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* getParcelInfo() {
    yield takeLatest(actions.GET_PARCEL_INFO, function* (action) {
        try {
            const {data} = action;
            const response = yield call(API.getParcelInfo, data);
            if (response && response.status === 200) {
                yield put(actions.setParcelInfo(response.data));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* getParcelBanners() {
    yield takeLatest(actions.GET_PARCEL_BANNERS, function* (action) {
        try {
            // const {data} = action;
            const response = yield call(API.getParcelBanners, {slug: "track_banner"});
            console.log('response', response);
            if (response && response.status === 200) {
                yield put(actions.setParcelBanners(response.data));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* Parcels() {
    yield all([
        fork(getParcelType),
        fork(getParcelInfo),
        fork(getParcelBanners),
    ]);
}

export default Parcels;