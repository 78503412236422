import {Map} from "immutable";
import actions from './actions';

const init_state = new Map();


const News = (state = init_state, action) => {
    switch (action.type) {
        case actions.SET_NEWS: {
            const {data,main_news, count = undefined} = action.data
            return state.set('newsData',data).set('mainNews',main_news).set('count', count);
        }
        case actions.SET_INNER: {
            return state.set('newsInner',action.data);
        }
        default:
            return state;
    }
};

export default News;