const actions = {
    GET_PAGES: 'GET_PAGES',
    SET_PAGES: 'SET_PAGES',
    GET_MODAL_DATA: 'GET_MODAL_DATA',
    GET_MENU: 'GET_MENU',
    SET_MENU: 'SET_MENU',
    SET_MENU_TREE: 'SET_MENU_TREE',
    SEND_FEEDBACK: 'SEND_FEEDBACK',
    SEND_FEEDBACK_SUCCESS: 'SEND_FEEDBACK_SUCCESS',
    SEND_PARTNER: 'SEND_PARTNER',
    SEND_PARTNER_SUCCESS: 'SEND_PARTNER_SUCCESS',
    SUBSCRIBE: 'SUBSCRIBE',
    GET_TEAM: 'GET_TEAM',
    SET_TEAM: 'SET_TEAM',
    GET_STAMPS: 'GET_STAMPS',
    SET_STAMPS: 'SET_STAMPS',
    GET_MODULE: 'GET_MODULE',
    SET_MODULE: 'SET_MODULE',
    GET_BLOCKS: 'GET_BLOCKS',
    SET_BLOCKS: 'SET_BLOCKS',
    GET_SOCIALS: 'GET_SOCIALS',
    SET_SOCIALS: 'SET_SOCIALS',
    SET_BANNERS: 'SET_BANNERS',
    SEND_INSURANCE_FORM: 'SEND_INSURANCE_FORM',
    GET_TARIFFS: 'GET_TARIFFS',
    SET_TARIFFS: 'SET_TARIFFS',
    SET_LAST_UPDATE: 'SET_LAST_UPDATE',
    SET_ALL_ZONES : 'SET_ALL_ZONES',
    getTariffs: data => {
        return {
            type: actions.GET_TARIFFS,
            data: data
        }
    },
    setTariffs: data => {
        return {
            type: actions.SET_TARIFFS,
            data: data
        }
    },
    getPages: data => {
        return {
            type: actions.GET_PAGES,
            data: data
        }
    },
    setPages: data => {
        return {
            type: actions.SET_PAGES,
            data: data
        }
    },
    getMenu: data => {
        return {
            type: actions.GET_MENU,
            data: data
        }
    },
    setMenu: data => {
        return {
            type: actions.SET_MENU,
            data: data
        }
    },
    setMenuTree: data => {
        return {
            type: actions.SET_MENU_TREE,
                data: data
        }
    },
    sendFeedback: data => {
        return {
            type: actions.SEND_FEEDBACK,
            data: data
        }
    },
    sendFeedbackSuccess: data => {
        return {
            type: actions.SEND_FEEDBACK_SUCCESS,
            data: data
        }
    },
    sendPartner: data => {
        return {
            type: actions.SEND_PARTNER,
            data: data
        }
    },
    sendPartnerSuccess: data => {
        return {
            type: actions.SEND_PARTNER_SUCCESS,
            data: data
        }
    },
    subscribe: data => {
        return {
            type: actions.SUBSCRIBE,
            data: data
        }
    },
    getTeam: data => {
        return {
            type: actions.GET_TEAM,
            data: data
        }
    },
    setTeam: data => {
        return {
            type: actions.SET_TEAM,
            data: data
        }
    },
    getStamps: data => {
        return {
            type: actions.GET_STAMPS,
            data: data
        }
    },
    setStamps: data => {
        return {
            type: actions.SET_STAMPS,
            data: data
        }
    },
    getModule: data => {
        return {
            type: actions.GET_MODULE,
            data: data
        }
    },
    setModule: data => {
        return {
            type: actions.SET_MODULE,
            data: data
        }
    },
    getBlocks: data => {
        return {
            type: actions.GET_BLOCKS,
            data: data
        }
    },
    setBlocks: data => {
        return {
            type: actions.SET_BLOCKS,
            data: data
        }
    },
    getSocial: data => {
        return {
            type: actions.GET_SOCIALS,
            data: data
        }
    },
    setSocial: data => {
        return {
            type: actions.SET_SOCIALS,
            data: data
        }
    },
    setBanners: data => {
        return {
            type: actions.SET_BANNERS,
            data: data
        }
    },
    getModalData: data => {
        return {
            type: actions.GET_MODAL_DATA,
            data: data
        }
    },
    sendInsuranceForm: data => {
        return {
            type: actions.SEND_INSURANCE_FORM,
            data: data
        }
    },
    setLastUpdate: data => {
        return {
            type: actions.SET_LAST_UPDATE,
            data: data
        }
    },
    setAllZones: data => {
        return {
            type: actions.SET_ALL_ZONES,
            data
        }
    }
};

export default actions;