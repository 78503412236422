import React from "react";
import {useSelector} from "react-redux";
import MenuTree from "../../../menu-tree/menu-tree";

const TopFooter = () => {

    const socials = useSelector(({Pages})=>Pages.get('socials'));

    if(!socials)
        return false

    return (
        <div className="footer_top">
            <MenuTree position="footer" className="footer"/>
            <ul className="socials_list">
                {
                    socials.map((item) => {
                        return <li key={item.id}><a href={item.url} target="_blank" rel="noreferrer" className={`icon_${item.icon_class}`}><span></span></a></li>
                    })
                }
            </ul>
        </div>
    )
};

export default TopFooter;