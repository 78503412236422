import React from "react";
import  moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/ru';
import 'moment/locale/hy-am';

import { useSelector} from "react-redux";

const Date = (props) => {
    const selected_language = useSelector(({Settings})=>Settings.get('selected_language'));
    const { date,format  = "DD.MM.YY" } = props;

    if(!date)
        return false;
    return (
        <span>{moment(date).locale(selected_language.code).format(format)}</span>
    )
};

export default Date;