import {all} from 'redux-saga/effects';
import Settings from "./settings/saga";
import OurNetwork from "./our-network/saga";
import Pages from "./pages/saga";
import Main from "./main/saga";
import Parcels from "./parcels/saga";
import News from "./news/saga";
import Career from "./career/saga";
import PostalIndex from "./postal-index/saga";
import Calculators from "./calculators/saga";

export default function* combinedSagas(){
    yield all([
        Settings(),
        OurNetwork(),
        Pages(),
        Main(),
        Parcels(),
        News(),
        Career(),
        PostalIndex(),
        Calculators()
    ]);
}