import React, {Fragment} from "react";
import BottomFooter from "./components/bottom-footer";
import TopFooter from "./components/top-footer";

import './footer.scss';

const Footer = () => {
 return (
     <Fragment>
         <footer className="footer">
             <div className="custom_container">
                <TopFooter />
                <BottomFooter />
             </div>
        </footer>
     </Fragment>
 )
};

export default Footer;