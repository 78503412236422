import {apiRequest} from "../../service/service";

class Api {
    getParcelType = (params = {}) => {
        const url = `parcels`;
        const config = {
            method:'get',
            url:url,
            params
        };
        return apiRequest(config);
    };
    getShippingMethods = (params = {}) => {
        const url = `methods`;
        const config = {
            method:'get',
            url:url,
            params
        };
        return apiRequest(config);
    };
    getParcelInfo = (params={}) => {
        const url = `trackingNumber/`;
        const config = {
            method:'get',
            url:url,
            params
        };
        return apiRequest(config);
    };

    getParcelBanners= (params={}) => {
        const url = `bannerGroup`;
        const config = {
            method:'get',
            url:url,
            params
        };
        return apiRequest(config);
    };
}

export default Api;
