import { lazy } from "react";


const T1 = lazy(() => {
    return import('../templates/T1')
});

const T2 = lazy(() => {
    return import('../templates/T2')
});

const L1T1 = lazy(() => {
    return import('../templates/L1T1')
});

const L1T2 = lazy(() => {
    return import('../templates/L1T2')
});

const L2T1 = lazy(() => {
    return import('../templates/L2T1')
});

const L2T2 = lazy(() => {
    return import('../templates/L2T2')
});

const L3T1 = lazy(() => {
    return import('../templates/L3T1')
});

const L4T1 = lazy(() => {
    return import('../templates/L4T1')
});

const L4T2 = lazy(() => {
    return import('../templates/L4T2')
});

const Templates = {
    1: T1,
    2: L4T2,
    3: L4T1,
    4: L3T1,
    5: L2T1,
    6: L2T2,
    7: L1T2,
    8: L1T1,
    9: T2
}


export default Templates;