import {Map} from "immutable";
import actions from './actions';

const init_state = new Map();


const Errors = (state = init_state, action) => {
    switch (action.type) {
        case actions.SET_ERROR: {
            const {page,data} = action.data;
            return state.set(page,data);
        }
        default:
            return state;
    }
};

export default Errors;