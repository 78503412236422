const actions = {
    GET_CURRENCIES: 'GET_CURRENCIES',
    SET_CURRENCIES: 'SET_CURRENCIES',
    GET_MONEY_TRANSFER: 'GET_MONEY_TRANSFER',
    SET_MONEY_TRANSFERS: 'SET_MONEY_TRANSFERS',
    GET_SECTIONS: 'GET_SECTIONS',
    SET_SECTIONS: 'SET_SECTIONS',
    GET_POSTAL_CALCULATOR: 'GET_POSTAL_CALCULATOR',
    GET_ADDITIONAL_TARIFFS: 'GET_ADDITIONAL_TARIFFS',
    SET_ADDITIONAL_TARIFFS: 'SET_ADDITIONAL_TARIFFS',
    SET_POSTAL_CALCULATOR: 'SET_POSTAL_CALCULATOR',
    SET_PARCEL_WEIGHT: 'SET_PARCEL_WEIGHT',
    SET_POSTAL_ORDERED: 'SET_POSTAL_ORDERED',
    SET_POSTAL_TRAJECTORY: 'SET_POSTAL_TRAJECTORY',
    SET_POSTAL_STANDARD: 'SET_POSTAL_STANDARD',
    SET_PRICE_INFO: 'SET_PRICE_INFO',
    SET_DELIVERY_TYPE: 'SET_DELIVERY_TYPE',
    SET_ADDITIONAL_SERVICES: 'SET_ADDITIONAL_SERVICES',
    SET_COUNTRY: 'SET_COUNTRY',
    SET_REGION: 'SET_REGION',
    SEND_DELAY_FORM: 'SEND_DELAY_FORM',
    SET_PARCEL_PRICE: 'SET_PARCEL_PRICE',
    SET_PARCEL_ESTIMATED_PRICE: 'SET_PARCEL_ESTIMATED_PRICE',
    getCurrencies: data => {
        return {
            type: actions.GET_CURRENCIES,
            data: data
        }
    },
    setCurrencies: data => {
        return {
            type: actions.SET_CURRENCIES,
            data: data
        }
    },
    getMoneyTransfer: data => {
        return {
            type: actions.GET_MONEY_TRANSFER,
            data: data
        }
    },
    setMoneyTransfer: data => {
        return {
            type: actions.SET_MONEY_TRANSFERS,
            data: data
        }
    },
    getSections: data => {
        return {
            type: actions.GET_SECTIONS,
            data: data
        }
    },
    setSections: data => {
        return {
            type: actions.SET_SECTIONS,
            data: data
        }
    },
    getPostalCalculator: data => {
        return{
            type: actions.GET_POSTAL_CALCULATOR,
            data: data
        }
    },
    setPostalCalculator: data => {
        return{
            type: actions.SET_POSTAL_CALCULATOR,
            data: data
        }
    },
    setAdditionalTariffs: data => {
        return{
            type: actions.SET_ADDITIONAL_TARIFFS,
            data: data
        }
    },
    setParcelWeight: data => {
        return{
            type: actions.SET_PARCEL_WEIGHT,
            data: data
        }
    },
    setPostalOrdered: data => {
        return{
            type: actions.SET_POSTAL_ORDERED,
            data: data
        }
    },
    setPostalTrajectory: data => {
        return{
            type: actions.SET_POSTAL_TRAJECTORY,
            data: data
        }
    },
    setPostalStandard: data => {
        return{
            type: actions.SET_POSTAL_STANDARD,
            data: data
        }
    },
    setPriceInfo: data => {
        return{
            type: actions.SET_PRICE_INFO,
            data: data
        }
    },
    setDeliveryType: data => {
        return{
            type: actions.SET_DELIVERY_TYPE,
            data: data
        }
    },
    getAdditionalTariffs: data => {
        return{
            type: actions.GET_ADDITIONAL_TARIFFS,
            data: data
        }
    },
    setAdditionalServices: data => {
        return{
            type: actions.SET_ADDITIONAL_SERVICES,
            data: data
        }
    },
    setCountry: data => {
        return{
            type: actions.SET_COUNTRY,
            data: data
        }
    },
    setRegion: data => {
        return{
            type: actions.SET_REGION,
            data: data
        }
    },
    sendDelayForm: data => {
        return{
            type: actions.SEND_DELAY_FORM,
            data: data
        }
    },
    setParcelPrice: data => {
        return{
            type: actions.SET_PARCEL_PRICE,
            data: data
        }
    },
    setParcelEstimatedPrice: data => {
        return{
            type: actions.SET_PARCEL_ESTIMATED_PRICE,
            data: data
        }
    },
};

export default actions;