import {Map} from "immutable";
import actions from './actions';

const init_state = new Map({
    postalOrdered: false,
    postalTrajectory: false,
    postalStandard: false,
    priceInfo: {},
    additionalServices: [],
    estimatedPrice: 0
});


const Calculators = (state = init_state, action) => {
    switch (action.type) {
        case actions.SET_CURRENCIES: {
            const {calculator,currencies} = action.data;
            return state.setIn([calculator,'currencies'], currencies);
        }
        case actions.SET_MONEY_TRANSFERS: {
            return state.setIn(['money','transfers'], action.data);
        }
        case actions.SET_POSTAL_CALCULATOR: {
            const { data } = action;
            const merge = Object.assign({},state.get('postalCalculator'),data);
            return state.set('postalCalculator',merge);
        }
        case actions.SET_ADDITIONAL_TARIFFS: {
            const { data } = action;
            return state.set('postalAdditionalTariffs',data);
        }
        case actions.SET_SECTIONS: {
            const {calculator,data} = action.data;
            return state.setIn([calculator,'sections'], data);
        }
        case actions.SET_PARCEL_WEIGHT: {
            const {data} = action;
            return state.set('parcelWeight', data);
        }
        case actions.SET_POSTAL_ORDERED: {
            const {data} = action;
            return state.set('postalOrdered', data);
        }
        case actions.SET_POSTAL_TRAJECTORY: {
            const {data} = action;
            return state.set('postalTrajectory', data);
        }
        case actions.SET_POSTAL_STANDARD: {
            const {data} = action;
            return state.set('postalStandard', data);
        }
        case actions.SET_PRICE_INFO: {
            const {data} = action;
            return state.set('priceInfo', data);
        }
        case actions.SET_DELIVERY_TYPE: {
            const {data} = action;
            return state.set('deliveryInfo', data);
        }
        case actions.SET_ADDITIONAL_SERVICES: {
            const {data} = action;
            return state.set('additionalServices', data);
        }
        case actions.SET_COUNTRY: {
            const {data} = action;
            return state.set('selectedCountry', data);
        }
        case actions.SET_REGION: {
            const {data} = action;
            return state.set('selectedRegion', data);
        }
        case actions.SET_PARCEL_PRICE: {
            const {data} = action;
            return state.set('parcelPrice', data);
        }
        case actions.SET_PARCEL_ESTIMATED_PRICE: {
            const {data} = action;
            return state.set('parcelEstimatedPrice', data);
        }
        default:
            return state;
    }
};

export default Calculators;