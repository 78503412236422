import axios from 'axios';
import ReactGA from 'react-ga';

const GA_CODE = process.env.REACT_APP_GA_CODE;
ReactGA.initialize(GA_CODE);

const API_URL = process.env.REACT_APP_API_PATH;

const instance = axios.create({
    baseURL: API_URL
});



export const apiRequest = (config) => {
    const { params,...rest } = config;
    const lng =  localStorage.getItem('locale') ? JSON.parse(localStorage.getItem('locale')).code : 'am';
    const apiParams = config.params
        ? {
            ...params,
            lng
        }
        : { lng};

    const conf = {
        ...rest,
        params: {...apiParams}
    };


    return instance(conf)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            if(!error.response){
                throw Error ('something_went_wrong');
            }
            // handle error
            const { text } = error.response.data;
            ReactGA.exception({
                description: `message: ${text.message}, type: server, error: ${text}`,
                fatal: true
            });
            throw Error (text);
        });
};
