import {Map} from "immutable";
import actions from './actions';

const init_state = new Map({
    allZones: []
});


const Pages = (state = init_state, action) => {
    switch (action.type) {
        case actions.SET_PAGES: {
            return state.set('current',action.data);
        }
        case actions.SET_MENU: {
            return state.set('menu',action.data);
        }
        case actions.SET_MENU_TREE: {
            return state.set('menuTree',action.data);
        }
        case actions.SEND_FEEDBACK_SUCCESS: {
            return state.set('messageSent',action.data);
        }
        case actions.SET_TEAM: {
            return state.set('team',action.data);
        }
        case actions.SET_STAMPS: {
            return state.set('stamps',action.data);
        }
        case actions.SET_MODULE: {
            return state.set('current_module',action.data);
        }
        case actions.SET_BLOCKS: {
            return state.set('blocks',action.data);
        }
        case actions.SET_SOCIALS: {
            return state.set('socials',action.data);
        }
        case actions.SET_BANNERS: {
            return state.set('banners',action.data);
        }
        case actions.SET_TARIFFS: {
            return state.set('tariffs',action.data);
        }
        case actions.SET_LAST_UPDATE: {
            return state.set('lastUpdate',action.data);
        }
        case actions.SET_ALL_ZONES: {
            if(!state.get('allZones').length){
                return state.set('allZones',action.data);
            } else{
                return state.set('allZones',state.get('allZones'));
            }
        }
        default:
            return state;
    }
};

export default Pages;