const actions = {
    GET_SITE_CONFIGS: 'GET_SITE_CONFIGS',
    SET_SITE_CONFIGS: 'SET_SITE_CONFIGS',
    SET_LANGUAGE: 'SET_LANGUAGE',
    GET_TRANSLATIONS: 'GET_TRANSLATIONS',
    SET_TRANSLATIONS: 'SET_TRANSLATIONS',
    getSiteConfigs: data => {
        return {
            type: actions.GET_SITE_CONFIGS,
            data: data
        }
    },
    setSiteConfigs: data => {
        return {
            type: actions.SET_SITE_CONFIGS,
            data: data
        }
    },
    getTranslations: data => {
        return {
            type: actions.GET_TRANSLATIONS,
            data: data
        }
    },
    setTranslations: data => {
        return {
            type: actions.SET_TRANSLATIONS,
            data: data
        }
    },
    setLanguage: data => {
        return {
            type: actions.SET_LANGUAGE,
            data: data
        }
    }
};

export default actions;